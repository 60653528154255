/* @import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap'); */

@font-face {
    font-family: Noto-Black;
    src: url('../public/font/NotoSansJP-Black.otf')
}

@font-face {
    font-family: Noto-Bold;
    src: url('../public/font/NotoSansJP-Bold.otf')
}

@font-face {
    font-family: Noto-Medium;
    src: url('../public/font/NotoSansJP-Medium.otf')
}

@font-face {
    font-family: Noto-Regular;
    src: url('../public/font/NotoSansJP-Regular.otf')
}

.black {
    font-family: Noto-Black;
}

.bold {
    font-family: Noto-Bold;
}

.medium {
    font-family: Noto-Medium;
}

.regular {
    font-family: Noto-regular;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.ui.breadcrumb .section {
    font-size: 12px !important;
    font-family: Noto-Bold;
}

.Toastify__close-button {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.Toastify__close-button--default {
    color: #000;
    opacity: 0.3;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    opacity: 1;
}

@keyframes Toastify__trackProgress {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

.Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
    opacity: 0.7;
    animation: Toastify__trackProgress linear 1 forwards;
    background-color: rgba(255, 255, 255, 0.7);
    transform-origin: left;
}

.Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
}

.Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

@keyframes Toastify__bounceInRight {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        transform: translate3d(10px, 0, 0);
    }

    90% {
        transform: translate3d(-5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}

@keyframes Toastify__bounceInLeft {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }

    75% {
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        transform: translate3d(5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes Toastify__bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    75% {
        transform: translate3d(0, 10px, 0);
    }

    90% {
        transform: translate3d(0, -5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}

@keyframes Toastify__bounceInDown {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }

    75% {
        transform: translate3d(0, -10px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
}

.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
    animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
    animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
    animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
    animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
    animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
    animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes Toastify__zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}

.Toastify__zoom-enter {
    animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
    animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        transform: perspective(400px);
    }
}

@keyframes Toastify__flipOut {
    from {
        transform: perspective(400px);
    }

    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

.Toastify__flip-enter {
    animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
    animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInLeft {
    from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInUp {
    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInDown {
    from {
        transform: translate3d(0, -110%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideOutRight {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0);
    }
}

@keyframes Toastify__slideOutLeft {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(-110%, 0, 0);
    }
}

@keyframes Toastify__slideOutDown {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0);
    }
}

@keyframes Toastify__slideOutUp {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0);
    }
}


.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
    animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
    animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
    animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
    animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
    animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
    animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
    animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
    animation-name: Toastify__slideOutDown;
}

.Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff;
}

.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em;
}

.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px;
}

.Toastify__toast-container--top-right {
    top: 1em;
    right: 1em;
}

.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em;
}

.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px;
}

.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
}

.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 3px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: Lato !important;
    cursor: pointer;
    direction: ltr;
}

.Toastify__toast--rtl {
    direction: rtl;
}

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}

.Toastify__toast--info {
    background: #3498db;
}

.Toastify__toast--success {
    background: #07bc0c;
}

.Toastify__toast--warning {
    background: #f1c40f;
}

.Toastify__toast--error {
    background: #e74c3c;
}

.Toastify__toast-body {
    margin: auto 0;
    -ms-flex: 1;
    flex: 1;
}

#root {
    height: 100%;
}

.main-container {
    height: 100%;
}

.children {
    min-height: 76%;
}

.main-container .header-section {
    height: auto;
}

.main-container .header-section .navbar-section.ui.menu {
    height: 81px;
    width: 100%;
    padding: 15px 50px;
    margin-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: none;
}


.main-container .header-section .navbar-section.ui.menu .right.menu.user-menu {
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    border-radius: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 15%;
    padding: 15px 17px 15px 22px;
    cursor: pointer;
    position: relative;
}

.main-container .header-section .navbar-section.ui.menu .right.menu.user-menu .user-menu {
    width: 100%;
}

.main-container .header-section .navbar-section.ui.menu .right.menu.user-menu .user-menu img:first-child {
    float: left;
}

.main-container .header-section .navbar-section.ui.menu .right.menu.user-menu .user-menu span {
    float: left;
    width: 68%;
    margin-left: 3px;
    font-size: 15px;
    font-family: Noto-Bold;
}

.main-container .header-section .navbar-section.ui.menu .user-items {
    position: absolute;
    top: 70px;
    right: 50px;
    width: 14%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
    border-radius: 4px;
    padding: 31px 28px 20px 20px;
}

.main-container .header-section .navbar-section.ui.menu .user-items:before {
    content: '';
    position: absolute;
    top: -12px;
    left: 139px;
    transform: rotate(180deg);
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 12px #FFFFFF;
    border-left: solid 7px transparent;
    border-right: solid 7px transparent;
    -webkit-filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 25%));
   filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 25%));
}

.main-container .header-section .navbar-section.ui.menu .user-items p img{
    float: right;
    padding-top: 6px;
}

.main-container .header-section .navbar-section.ui.menu .user-items p {
    font-size: 15px;
    font-family: Noto-Medium;
    cursor: pointer;
}

.main-container .header-section .navbar-section.ui.menu .right.menu.user-menu .user-menu .icon {
    float: right;
}

.main-container .header-section .navbar-section.ui.menu .item:first-child {
    padding-left: 0;
    margin-right: 18px;
}

.main-container .header-section .navbar-section.ui.menu .item .logo-img {
    width: 134px;
    height: 31px;
    cursor: pointer;
}

.main-container .header-section .navbar-section.ui.menu .item .ui.search .ui.input {
    width: 480px;
    height: 40px;
}

.main-container .header-section .navbar-section.ui.menu .item .ui.search .ui.input input {
    background: #F1F1F1;
    border-radius: 5px;
    border: none;
}

.main-container .header-section .navbar-section.ui.menu .right.menu .right-menu-text {
    margin: auto;
    cursor: pointer;
    padding-right: 30px;
    padding-top: 6px;
}

.main-container .header-section .navbar-section.ui.menu .right.menu .right-menu-text img {
    padding-right: 10px;
}

.main-container .header-section .navbar-section.ui.menu .right.menu .right-menu-text span {
    font-family: Noto-Bold;
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 100%;
    color: #231815;
    vertical-align: super;
}

.main-container .header-section .navbar-section.ui.menu .right.menu .ui.button {
    background: #FA7575;
    border-radius: 4px;
    padding: 17px 25px 13px;
}

.main-container .header-section .navbar-section.ui.menu .right.menu .ui.button span {
    font-family: Noto-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    padding-left: 10px;
    vertical-align: super;
}

.main-container .header-section .category-section {
    background: #58BAF4;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.main-container .header-section .category-section p {
    font-family: Noto-Bold;
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    cursor: pointer;
    margin: auto;
    padding-top: 3px;
}

.main-container .header-section .category-section p i.icon {
    padding-left: 15px;
    vertical-align: middle;
    font-size: 24px;
    margin-bottom: 2px;
}

.main-container .header-section .categories-list.show {
    height: 55px;
    background: #F6F6F6;
    text-align: center;
    display: inherit;
}

.main-container .header-section .categories-list.hide {
    display: none;
}

.main-container .header-section .categories-list .ui.dropdown {
    font-family: Noto-Bold;
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 100%;
    color: #58BAF4;
    margin-left: 26px;
    margin-right: 26px;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.main-container .header-section .categories-list .ui.dropdown.active {
    background: #FFFFFF;
}

.main-container .header-section .categories-list .ui.dropdown.active .visible.menu.transition {
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    border-radius: 0;
    border-top: none;
    width: -webkit-fill-available;
    box-shadow: none;
}

.main-container .header-section .categories-list .ui.dropdown.active .visible.menu.transition .item {
    font-family: Noto-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #231815;
}

.main-container .header-section .categories-list .ui.dropdown>.text {
    padding-left: 10px;
}

.main-container .header-section .categories-list .ui.dropdown i.icon {
    margin-left: 12px;
}

.main-container .about-section {
    background: #FAFAFA;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 68px;
    padding-bottom: 68px;
}

.main-container .about-section .text-section {
    margin-right: 96px;
}

.main-container .about-section .text-section p {
    font-family: Noto-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #231815;
}

.main-container .about-section .text-section p:first-child {
    margin-bottom: 0;
}

.main-container .about-section .text-section p:nth-child(2) {
    margin-bottom: 50px;
}

.main-container .footer-section {
    text-align: center;
    margin-top: auto;
}

.footer-section img {
    margin-top: 100px;
    margin-bottom: 98px;
    width: 770px;
    height: 184px;
}

.footer {
    width: 100%;
}

.footer .ui.menu {
    background: #58BAF4;
    height: 65px;
    border-radius: 0;
    padding-right: 130px;
    padding-left: 81px;
}

.underline {
    text-decoration: underline !important;
}

.footer .ui.menu .item {
    margin: 0;
    font-family: Noto-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.footer .ui.menu .item:hover,
.footer .ui.menu .item.active {
    color: white;
}

.footer .ui.menu .item:before {
    content: '/';
    width: 0;
    top: 40%;
}

.footer .ui.menu .item:nth-child(4):before,
.footer .ui.menu .item:nth-child(5):before {
    content: '';
}

.footer .ui.menu .item img {
    width: 17px;
    height: 16px;
    margin-left: 12px;
}

.footer .ui.menu .item:nth-child(5) {
    padding-left: 29px;
}

.footer .ui.menu .menu.right,
.footer-section.mobile .footer .ui.menu .menu.left {
    color: #C2E8FE;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.show-more {
    font-family: Noto-Bold;
    font-style: normal;
    /* font-weight: 700; */
    font-size: 16px;
    line-height: 100% !important;
    letter-spacing: 0.06em;
    text-decoration-line: underline;
    display: inline;
    cursor: pointer;
}

.ui.search .results.transition.visible {
    display: none !important;
}

.slick-track {
    width: max-content !important;
}

.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 20px;
    left: initial;
    right: 25px;
    top: 15px;
}


/* Color/shape of burger icon bars on hover*/
/*.bm-burger-bars-hover {
    background: lightgray;
}*/

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross-button span {
    right: 4px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: white;
    width: 2px !important;
    height: 18px !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    text-align: center;
    padding-top: 110px;
    color: white;
}

.bm-item-list .item.bm-item {
    margin-bottom: 15px;
}

.bm-item-list .bm-item.static-pages {
    margin-top: 75px;
    padding: 0 16px;
}

.bm-item-list .bm-item.static-pages .item {
    border-top: 1px solid #73C2F1;
    padding: 17px 0 18px 16px;
    text-align: left;
}

.bm-item-list .bm-item.static-pages .item:last-child {
    border-bottom: 1px solid #73C2F1;
}

.bm-item-list .bm-item.static-pages .item h3 {
    font-size: 15px;
}

.bm-item-list .item.bm-item h3 {
    font-size: 15px;
    width: 274px;
    height: 50px;
    line-height: 50px;
    margin: auto;
    text-align: center;
    border-radius: 4px;
}

.bm-item-list .item.bm-item:first-child h3 {
    background: #FA7575;
}

.bm-item-list .item.bm-item:nth-child(2) h3 {
    background: white;
    color: #231815;
}

/* Individual item */
.bm-item {
    display: inline-block;
    cursor: pointer;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
}

.navbar-section.mobile {
    border: 0;
    border-radius: 0 !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    height: 48px;
}

.navbar-section.mobile.with-search {
    height: auto;
}

.navbar-section.mobile .logo-img {
    width: 88px;
    height: 20px;
    margin: 12px 0 9px 16px;
    cursor: pointer;
}

.navbar-mobile-elements {
    display: flex;
    width: 100%;
    padding-right: 65px;
}

.navbar-mobile-elements .item {
    width: 50%;
}

.navbar-mobile-elements .item:nth-child(2) {
    width: 50%;
    align-items: center;
    justify-content: right;
    display: flex;
}

.bm-menu-wrap {
    background: #58BAF4 !important;
    width: 100% !important;
    height: 542px !important;
}

.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 20px;
    left: auto;
    right: 25px;
    top: 15px;
}

.bm-burger-bars {
    background: #231815;
    width: 19px;
    height: 3px !important;
}

.main-container.mobile .categories-list.show {
    display: inline-grid;
    width: 100%;
    height: auto;
}

.main-container.mobile .categories-list .ui.dropdown {
    padding-top: 20px;
    padding-bottom: 20px;
    display: block;
    width: 100%;
    border-bottom: 1px solid #DCDCDC !important;
    margin-right: 0;
    margin-left: 0;
}

.main-container.mobile .categories-list .ui.dropdown>.text {
    padding-left: 16px;
}

.main-container.mobile .categories-list .ui.dropdown i.icon {
    float: right;
    margin-right: 20px;
}

.show-mobile-search {
    cursor: pointer;
}

.mobile-search-section {
    text-align: center;
    height: 108px;
    background: white;
}

.mobile-search-section .close-img {
    text-align: right;
}

.mobile-search-section img {
    cursor: pointer;
    padding: 14px 16px 0 0;
}

.mobile-search-section .item {
    margin-top: 20px;
}

.mobile-search-section .ui.search .ui.input {
    width: 343px;
    height: 40px;
}

.mobile-search-section .ui.search .ui.input input {
    background: #F1F1F1;
    border-radius: 5px;
    border: none;
}

.mobile.about-section {
    display: inline-block;
    padding-top: 14px 0;
}

.about-section.mobile .text-section {
    margin: auto auto 30px auto;
}

.mobile.about-section .text-section p {
    font-style: normal;
    font-family: Noto-Medium;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #231815;
}

.about-section.mobile .image-section img:first-child {
    width: 93%;
    margin-bottom: 30px;
}

.about-section.mobile .image-section img:last-child {
    display: block;
    margin: auto;
}

.main-container .header-section.mobile .category-section {
    height: 0;
}

@media (max-width: 1150px) {
    .main-container .header-section .navbar-section.ui.menu {
        padding: 15px 10px;
    }

    .footer .ui.menu {
        padding-right: 5px;
        padding-left: 10px;
    }
}


@media (max-width: 900px) {
    .about-section {
        padding-right: 45px;
        padding-left: 45px;
    }

    .about-section .image-section img:first-child {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .footer-section.mobile .footer .ui.menu {
        margin-bottom: 0;
        margin-top: 0;
        border: 0;
        height: auto;
        padding-left: 6px;
    }

    .footer-section.mobile .footer .ui.menu:first-child {
        padding-top: 20px;
    }

    .footer-section.mobile .footer .ui.menu:nth-child(3) {
        padding-bottom: 20px;
    }

    .footer-section.mobile .footer .ui.menu:last-child {
        padding-left: 16px;
        padding-bottom: 20px;
    }

    .footer-section.mobile .footer .ui.menu .item {
        padding: 0 10px 0 10px;
    }

    .footer-section.mobile .ui.menu:nth-child(2) .item:last-child:before,
    .footer-section.mobile .ui.menu:nth-child(3) .item:last-child:before {
        content: '';
    }
}