.service-details-page {
    padding: 50px 0 20px 0px;
}

.service-details-page .ui.breadcrumb {
    padding-left: 80px;
}

.service-details {
    background: #FAFAFA;
    text-align: center;
    margin-top: 20px;
}

.service-details .first-img {
    padding-top: 100px;
    width: 100%;
}

.service-details .image-section {
    width: 70%;
    margin: auto;
}

.service-details .image-section .category-btn {
    float: left;
    margin-top: 32px;
    font-style: normal;
    font-family: Noto-Bold;
    /* font-weight: 700; */
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: #231815;
    border-radius: 20px;
    padding: 11px 20px;
}

.service-details .image-section .like-section {
    text-align: right;
    margin-top: 25px;
}

.service-details .image-section .like-section img {
    vertical-align: middle;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
}

.service-details .image-section .like-section .ui.button {
    font-family: Noto-Bold;
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    background: #FA7575;
    padding: 13px 40px;
}

.service-details .details-section {
    width: 70%;
    margin: 50px auto;
    text-align: left;
}

.service-details .details-section p {
    font-size: 15px;
    font-family: Noto-Medium;
}

.service-details .details-section .name h1 {
    font-size: 30px;
    font-family: Noto-Medium;
}

.service-details .details-section .price {
    margin-top: 50px;
}

.service-details .details-section .price .ui.button {
    font-style: normal;
    padding: 18px 80px;
    font-family: Noto-Medium;
    /* font-weight: 500; */
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    color: #231815;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-right: 24px;
}

.service-details .details-section .points-section {
    margin-top: 50px;
}

.service-details .details-section .points-section h3 {
    font-size: 20px;
    font-style: normal;
    font-family: Noto-Bold;
    /* font-weight: 700; */
    line-height: 100%;
    color: #FA7575;
}

.service-details .details-section .points-section .points-list {
    margin-top: 20px;
}

.service-details .details-section .points-section .points-list .single-point {
    padding: 15px 18px;
    width: 50%;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #C2C2C2;
    margin-bottom: 10px;
}

.service-details .details-section .points-section .points-list .single-point p {
    font-size: 15px;
}

.service-details .details-section .points-section .points-list .single-point img {
    padding-right: 18px;
}

.service-details .details-section .images .small-images {
    display: flex;
    margin-top: 20px;
}

.service-details .details-section .images .small-images img {
    width: 26%;
    margin-right: 20px;
}

.service-details .details-section .images .small-images img:last-child {
    width: 15%;
}

.service-details .details-section .introduction-section {
    margin-top: 50px;
}

.service-details .details-section .introduction-section p {
    font-size: 15px;
}

.service-details .details-section .introduction-section a {
    font-style: normal;
    font-weight: 500;
    font-family: Noto-Medium;
    font-size: 15px;
    line-height: 160%;
    text-decoration-line: underline;
    color: #FA7575;
}

.service-details .details-section .introduction-section a img {
    padding-left: 10px;
    margin-bottom: -4px;
}

.service-details .details-section .custom-list {
    margin-top: 50px;
    text-align: center;
}

.service-details .details-section .custom-list .ui.button {
    padding: 18px 80px;
    background: #FA7575;
    border-radius: 4px;
    font-family: Noto-Bold;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    margin-top: 100px;

}

.service-details .details-section .custom-list .single-custom-item {
    width: 100%;
    display: flex;
    border-top: none;
    border-bottom: 1px solid #C2C2C2;
    text-align: left;
}

.service-details .details-section .custom-list .single-custom-item:first-child {
    border-top: 1px solid #C2C2C2;
}

.service-details .details-section .custom-list .single-custom-item h3 {
    width: 30%;
    padding: 18px;
    margin-bottom: 0;
    background: #F6F6F6;
    font-style: normal;
    font-family: Noto-Bold;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 100%;
    color: #231815;
}

.service-details .details-section .custom-list .single-custom-item p {
    width: 70%;
    padding: 18px;
    font-style: normal;
    font-family: Noto-Regular;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #231815;
}

.service-details .details-section .border {
    border: 5px solid #FFFFFF;
    margin-top: 100px;
    margin-bottom: 100px;
}

.service-details .details-section .related-services {
    margin-top: 50px;
}

.service-details .details-section .related-services .lists .card {
    margin-bottom: 50px;
}

.service-details .details-section .related-services h2 {
    font-size: 25px;
    margin-top: 0px;
}

.subscribe-modal.ui.modal {
    border-radius: 20px;
    width: 55.556%;
    top: 85px;
    height: fit-content
}

.subscribe-modal.ui.modal>:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.subscribe-modal.ui.modal>.icon:first-child+*,
.subscribe-modal.ui.modal>:first-child:not(.icon) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.subscribe-modal.ui.modal .content {
    padding: 100px 12.5% 78px;
}

.subscribe-modal.ui.modal .content .header-section {
    text-align: center;
}

.subscribe-modal.ui.modal .content .header-section .header-text {
    font-style: normal;
    font-family: Noto-Medium;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 100%;
    color: #000000;
    padding-bottom: 8px;
    width: fit-content;
    text-align: center;
    margin: 0 auto 47px;
    border-bottom: 5px solid #F6F6F6;
}

.subscribe-modal.ui.modal .content .header-section p:nth-child(2) {
    font-style: normal;
    font-family: Noto-Medium;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #6F6F6F;
    margin-bottom: 16px;
}

.subscribe-modal.ui.modal .content .header-section p:last-child {
    font-style: normal;
    font-family: Noto-Medium;
    font-weight: 500;
    font-size: 25px;
    line-height: 100%;
    color: #000000;
}

.subscribe-modal.ui.modal .content .ui.vertical.accordion.ui.menu {
    width: 70%;
    background: #FFFFFF;
    border: 0.5px solid #373737;
    border-radius: 20px;
    margin: 40px auto 50px;
}

.subscribe-modal.ui.modal .content .ui.vertical.accordion.ui.menu .item {
    background: #EBEBEB;
    padding: 0;
    border-radius: 20px;
}

.subscribe-modal.ui.modal .content .ui.vertical.accordion.ui.menu .title {
    font-style: normal;
    font-weight: 500;
    font-family: Noto-Medium;
    font-size: 15px;
    line-height: 100%;
    color: #000000;
    background: #EBEBEB;
    padding: 22px 20px 22px 28px;
    border-bottom: 0.5px solid #111111;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.subscribe-modal.ui.modal .content .ui.vertical.accordion.ui.menu .content {
    background: #FFFFFF;
    padding: 20px 18px;
    border-bottom: 0.5px solid #949494;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.subscribe-modal.ui.modal .content .ui.vertical.accordion.ui.menu .content .item-list {
    display: flex;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #949494;
}

.subscribe-modal.ui.modal .content .action-section {
    text-align: center;
}

.subscribe-modal.ui.modal .content .action-section .ui.ui.button {
    font-style: normal;
    font-family: Noto-Bold;
    font-weight: 700;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    background: #FA7575;
    border-radius: 4px;
    width: 54.5%;
    height: 50px;
    margin-bottom: 20px;
}

.subscribe-modal.ui.modal .content .action-section p {
    font-style: normal;
    font-family: Noto-Medium;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    text-decoration-line: underline;
    color: #000000;
    cursor: pointer;
}

.subscribe-modal.ui.modal .content .ui.form{
    width: 70%;
    margin: auto;
}

.subscribe-modal.ui.modal .content .ui.form .field {
    margin-bottom: 40px;
}

.subscribe-modal.ui.modal .content .ui.form .fields {
    margin-bottom: 0;
}

.subscribe-modal.ui.modal .content .ui.form .fields span{
    padding: 20px;
}

.subscribe-modal.ui.modal .content .ui.form .field>.ui.label,
.subscribe-modal.ui.modal .content .ui.form .ui.label {
    font-style: normal;
    font-family: Noto-Medium;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #231815;
    background: transparent;
    padding: 0 0 10px 0;
    width: 100%;
}

.subscribe-modal.ui.modal .content .ui.form .field .ui.input {
    font-family: Noto-Medium;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.1em;
    width: 100%;
}

.subscribe-modal.ui.modal .content .ui.form .field .ui.input input {
    width: 70%;
    padding: 15.5px 20px 15.5px 14px;
    background: #FFFFFF;
    border: 0.5px solid #231815;
    border-radius: 5px;
}

.subscribe-modal.ui.modal .content .ui.form p {
    font-style: normal;
    font-family: Noto-Regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #000000;
    margin-bottom: 40px;
}